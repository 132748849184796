<template>
  <v-container class="container-position" fluid text-xs-center>
    <v-layout row wrap style="margin: 0px">
      <v-flex v-if="!isConnected && !whatsappRegistered" xs12 sm12 md12 style="padding-bottom: 1%;padding-top:1%">
        <WhatsappConnector :is-connected="isConnected" :whatsapp-registered="whatsappRegistered"
          :set-chat-show="setChatShow" />
      </v-flex>

      <v-container v-if="!isConnected && !whatsappRegistered && !firstTimeBroadcast" fluid class="whiteBackGround">
        <first-page :img-src="imgSrc" :dialog-name.sync="firstTimeBroadcast" :points="points"
          :headline="headline"></first-page>
      </v-container>

      <v-flex v-if="isConnected && whatsappRegistered" xs12 sm12 md12 style="padding: 1%">
        <v-card v-if="initialLoader" class="pa-3 d-flex justify-center align-center">
          <v-progress-circular indeterminate color="green" class="mr-2"></v-progress-circular>
          Fetching details. Please wait....
        </v-card>
        <v-flex v-else>
          <v-card-title v-if="filteredCamp && filteredCamp.length > 0">
            <v-row wrap style="margin: 0px" @keyup.enter="query">
              <v-flex xs12 sm8 md8 class="">
                <v-text-field v-model="search" prepend-inner-icon="search" clearable solo-inverted flat dense
                  single-line @click:clear="filterCampaign('')"></v-text-field>
              </v-flex>

              <v-flex xs12 sm4 md4 class="px-1 mt-3 mt-sm-0 mt-md-0 mt-xs-0">
                <v-btn color="primarygrad white--text" block elevation="3" @click="filterCampaign(search)">
                  <v-icon left>search</v-icon>
                  Search
                </v-btn>
              </v-flex>
            </v-row>
          </v-card-title>

          <v-data-table v-if="filteredCamp && filteredCamp.length > 0" :headers="headers" :items="filteredCamp"
            item-key="id" disable-sort :items-per-page="filteredCamp.length" hide-default-footer>
            <template v-slot:[`item.campaignName`]="{ item }">
              {{ item.campaignName }}
            </template>
            <template v-slot:[`item.platform`]="{ item }">
              {{ item.platform }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip class="ma-2" :color="item.status && item.status.pending > 0 ? 'orange' : 'green'
                " text-color="white">
                {{
                  item.status && item.status.pending > 0 ? "Pending" : "Sent"
                }}
              </v-chip>
            </template>
            <template v-slot:[`item.sent`]="{ item }">
              {{ item.status.success }}
            </template>
            <template v-slot:[`item.failed`]="{ item }">
              {{ item.status.failed }}
            </template>
            <template v-slot:[`item.pending`]="{ item }">
              {{ item.status.pending }}
            </template>
            <template v-slot:[`item.createdOn`]="{ item }">
              {{ $moment(item.createdOn).format("lll") }}
            </template>
          </v-data-table>

          <v-card-text v-if="filteredCamp && filteredCamp.length <= 0">
            <v-container fill-height fill-width class="justify-center">
              <div justify="center" align="center">
                <v-img :src="noAgent" width="200" />
                <h3 class="my-3">
                  You currently have not created any broadcast campaigns.
                </h3>
                <v-btn color="primarygrad" @click="openBroadCastDialog">
                  <v-icon left>add_circle</v-icon>Create Broadcast
                </v-btn>
              </div>
            </v-container>
          </v-card-text>

          <v-dialog v-model="broadcastDialog" persistent width="550">
            <v-card>
              <v-card-title class="primarygrad white--text">
                {{ formTitle }}
                <v-spacer></v-spacer>
                <v-btn icon @click="close">
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text class="pb-0 mb-0">
                <v-text-field v-model="defaultItem.name" class="my-4" label="Broadcast Title *" solo hide-details
                  dense />

                <v-autocomplete v-model="selectedlist" label="Lists *" autocomplete="off" :items="lists"
                  item-text="name" item-value="id" class="mt-2" @change="getContactsByList()" />
                <v-alert v-if="!loader && warning" border="right" colored-border type="error" elevation="2">
                  Your list contains {{ contactLength }} contacts. You are
                  allowed to send maximum 2000 contacts at a time.
                </v-alert>

                <v-autocomplete v-model="selectedTemplates" label="Templates *" autocomplete="off" :items="allTemplates"
                  item-text="name" item-value="id" class="mt-2" return-object />
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="red" @click="close">close</v-btn>
                <v-btn v-if="formTitle == 'Create Broadcast' && !warning" depressed color="primarygrad white--text"
                  @click="save">Send</v-btn>
                <v-btn v-if="formTitle == 'Create Broadcast' && warning" depressed disabled>Send</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-flex>

        <!-- <v-dialog v-model="broadEnableWhatsappDialog" persistent width="550">
          <v-card-text>
            <v-container fill-height fill-width class="justify-center">
              <div justify="center" align="center">
                <v-img :src="noAgent" width="200" />
                <h3 class="my-3">
                  You need to enable whatsapp in order to use Facebook
                  business.Please enable whatsapp here and also at agent's
                  setting page to ensure whatsapp are enabled.
                </h3>
                
              </div>
            </v-container>
          </v-card-text>
        </v-dialog> -->

        <v-btn v-if="
          companyDetails.enableWhatsapp && isConnected && whatsappRegistered
        " color="green" class="custom-position" fab fixed bottom right dark @click="openBroadCastDialog">
          <v-icon>mdi-plus</v-icon>
        </v-btn>

        <v-btn v-if="
          !companyDetails.enableWhatsapp && isConnected && whatsappRegistered
        " color="orange" class="custom-position" fab fixed bottom right dark @click="openDialogForWhatsappEnabled">
          <v-icon>mdi-information</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
    <!-- Loader -->
    <v-dialog v-model="loaderWait" persistent width="300">
      <v-card color="primarygrad white--text" dark>
        <v-card-text style="padding: 18px 25px">
          <h3 style="margin-bottom: 5px; font-weight: 400; font-size: 16px">
            Please Wait...
          </h3>
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";

const _ = require("lodash");
import { Datetime } from "vue-datetime";
// import fb from "@/js/firebase";
import io from "socket.io-client";
import moment from "moment-timezone";
import WhatsappConnector from "../../components/whatsappConnector.vue";
import firstPage from "@/components/firstPage.vue";

export default {
  components: {
    datetime: Datetime,
    WhatsappConnector,
    firstPage,
  },
  data() {
    return {
      firstTimeBroadcast: false,
      noDialog: false,
      headline: "Reach More with Broadcast Messaging",
      points: [
        "Send Targeted Messages to Large Groups: Easily broadcast pre-written messages to a wider audience, keeping everyone informed.",
        "Save Time & Resources: Avoid repetitive messaging by sending pre-designed broadcasts to large user groups simultaneously.",
        "Increase Engagement & Drive Action: Deliver targeted messages that resonate with your audience and promote desired actions.",
      ],
      contactLength: "",
      broadEnableWhatsappDialog: false,
      isConnected: false,
      whatsappRegistered: false,
      socket: false,
      selectedTemplates: {},
      search: "",
      allTemplates: [],
      dateReminder: "0",
      selectedlist: null,
      defaultItem: {
        name: "",
        reminder: true,
        remindOn: this.$moment().format(),
        done: false,
      },
      lists: [],
      filteredCamp: [],
      broadcastDialog: false,
      editedIndex: -1,
      totalCampaigns: null,
      warning: false,
      warningContactLength: 0,
      loader: false,
      contacts: null,
      initialLoader: false,
      headers: [
        { text: "Campaign Name", value: "campaignName" },
        { text: "Platform", value: "platform" },
        { text: "Status", value: "status" },
        { text: "Sent", value: "sent" },
        { text: "Failed", value: "failed" },
        { text: "Pending", value: "pending" },
        { text: "Created On", value: "createdOn" },
      ],
      loaderWait: false,
      // totalCampaigns: [
      //   {
      //     name: "Broadcast 1",
      //     listName: "Default",
      //     listId: "660acc141568925c96ec02d0",
      //     templateName: "Initial Templates",
      //     sent: false,
      //     totalContact: 350,
      //     submitted: 254,
      //   },
      //   {
      //     name: "Broadcast 2",
      //     listName: "Whatsapp",
      //     listId: "660acc141568925c96ec02d5",
      //     templateName: "Welcome Messages",
      //     sent: true,
      //     totalContact: 250,
      //     submitted: 234,
      //   },
      // ],
    };
  },
  computed: {
    ...mapState([
      "selectedTeam",
      "ENDPOINT",
      "teams",
      "user",
      "companyDetails",
    ]),
    selectedTeam: {
      get(v) {
        return this.$store.getters.selectedTeam;
      },
      set(v) {
        this.$store.commit("setSelectedTeam", v);
      },
    },
    noAgent() {
      return require("../../assets/noAgent.png");
    },
    imgSrc() {
      return require("@/assets/gobroadcast.svg");
    },
    teams() {
      return this.$store.getters.teams;
    },
    formTitle() {
      if (this.editedIndex === -1) return "Create Broadcast";
      else return "Edit Broadcast";
    },
  },
  watch: {},
  async mounted() {
    try {
      const getIntegration = await this.$http.get(
        `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.user.companyId}&filter[where][type]=whatsapp`
      );

      if (
        getIntegration.body.length &&
        getIntegration.body[0].config.initializeDone
      ) {
        this.setChatShow();
        await this.init();
      }
    } catch (e) {
      console.log(e);
    }

    // To track campaign changes without waiting for the loop to complete
    // console.log("Socket ENV", process.env.VUE_APP_ENDPOINT);
    // this.socket = io(process.env.VUE_APP_UIENDPOINT, {
    //   // path: "/meta/socket.io",
    //   transports: ["websocket"],

    //   reconnection: true,
    //   reconnectionAttempts: 5,
    //   reconnectionDelay: 1000,
    //   reconnectionDelayMax: 5000,
    //   timeout: 30000, // 20 seconds before considering a connection attempt as failed
    // }); // Adjust the URL to your server's

    // this.socket.on("connect", function () {
    //   console.log("Connected to SOCKET");
    // });

    // this.socket.on("connect_error", (error) => {
    //   console.log("Connection Error:", error);
    // });

    // this.socket.on("connect_timeout", (timeout) => {
    //   console.log("Connection Timeout:", timeout);
    // });

    // this.socket.on("campaign-started", async (campaign) => {
    //   console.log("campaign: ", campaign.campaign);
    //   this.totalRecords.push(campaign);
    //   this.broadcastDialog = false;
    //   this.$swal({
    //     type: "success",
    //     text: `${campaign.campaign.campaignName} is created successfully`,
    //   });
    // });
  },
  async created() {
    const self = this;
  },

  methods: {
    openDialogForWhatsappEnabled() {
      var self = this;
      return this.$swal({
        title: "Warning!",
        text:
          "You need to enable whatsapp in order to use Facebook business.Please enable whatsapp here and also at agent's setting page to ensure whatsapp are enabled.",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1bca3f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Go to Settings",
      })
        .then((willGo) => {
          if (willGo.value) {
            self.$router.push({ path: "/whatsapp-settings" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setChatShow() {
      this.isConnected = true;
      this.whatsappRegistered = true;
    },
    async filterCampaign(searchText) {
      const lowercasedSearchText = searchText.toLowerCase();

      this.filteredCamp = this.totalCampaigns.filter((campaign) => {
        const { campaignName } = campaign;
        // const { name, phone } = contact;
        // Ensure all text fields are treated as strings and converted to lowercase
        const nameMatch = campaignName
          .toLowerCase()
          .includes(lowercasedSearchText);

        // Return true if any of the fields match the search text
        return nameMatch;
      });
    },
    // async sendBroadcast() {
    //   console.log("broadcast fired");
    //   const data = {
    //     contactArray: [
    //       {
    //         id: "662772dd5614476928b77c9d",
    //         name: "Unknown",
    //         phone: "918621972781",
    //         secondPhone: "",
    //         email: "",
    //         note: "",
    //         remarks: "",
    //         extra: "",
    //         called: false,
    //         createdOn: "2024-04-23T08:35:40.998Z",
    //         callbackDone: false,
    //         dispo: "",
    //         callback: false,
    //         lock: false,
    //         dnc: false,
    //         ftsContact: false,
    //         linkSent: false,
    //         emailSent: false,
    //         appSent: false,
    //         leadScore: 60,
    //         smsSent: false,
    //         stageId: "",
    //         internalCompanyId: null,
    //         productIds: [],
    //         companyId: "5e9d3b85effbd9313c5e4f26",
    //         listId: "6616275f924c254ddcdfdec2",
    //       },
    //     ],
    //     template: {
    //       name: "service",
    //       components: [
    //         {
    //           type: "HEADER",
    //           format: "TEXT",
    //           text: "Requested service",
    //         },
    //         {
    //           type: "BODY",
    //           text: "Hey this is service request.",
    //         },
    //         {
    //           type: "FOOTER",
    //           text: "Request service",
    //         },
    //       ],
    //       language: "en_US",
    //       status: "APPROVED",
    //       category: "MARKETING",
    //       id: "1639500100219061",
    //     },
    //     campaign: {
    //       name: "test",
    //     },
    //   };
    //   const resp = await this.$http.post(
    //     `${this.ENDPOINT}/whatsapps/sendBulkTemplates`,
    //     data
    //   );
    // },
    /** SEARCH */
    async query() {
      try {
        if (this.search != "" && this.searchField != "") {
          this.loading = true;
          this.loadingText = "Loading... Please wait";
          this.page = 1;
        }
        this.fetchCount();
        this.contactDetails = await this.fetchLogs(
          this.records,
          this.records * this.page - this.records
        );
      } catch (e) {
        // error
      }
    },
    searchReset() {
      this.searchField = "name";
      this.search = "";
      this.query();
    },
    async init() {
      this.initialLoader = true; // Start with loader active
      try {
        await Promise.all([
          this.getWhatsappCampaign(),
          this.fetchListByTeam(),
          this.getTemplates(),
        ]);
        // The Promise.all method will wait for all passed promises to resolve.
        // This is more efficient than awaiting each one sequentially if they don't depend on each other.
      } catch (e) {
        console.error("Error during initialization:", e);
        // Handle errors, possibly show user feedback
      } finally {
        this.initialLoader = false; // Ensure loader is turned off after operations complete
      }
    },
    getWhatsappCampaign() {
      var self = this;

      this.$http
        .get(
          this.$store.state.ENDPOINT +
          "/whatsappCampaigns?filter[where][companyId]=" +
          this.$store.getters.user.companyId +
          "&filter[fields]=id&filter[fields]=campaignName&filter[fields]=status&filter[fields]=platform&filter[fields]=createdOn"
        )
        .then(
          (response) => {
            response = response.body;

            self.totalCampaigns = response;

            self.totalCampaigns.sort((a, b) => {
              const dateA = new Date(a.createdOn);
              const dateB = new Date(b.createdOn);
              return dateB - dateA; // Subtract to sort in descending order
            });

            self.filteredCamp = self.totalCampaigns;
          },
          (error) => {
            this.$swal({
              type: "error",
              text: "Something went wrong while loading the whatsapp campaign.",
            });
          }
        );
    },
    async save() {
      var self = this;

      // const regex = /^[a-z_]+$/;
      // if (!regex.test(this.template.title.trim())) {
      //   return this.$swal({
      //     type: "error",
      //     text:
      //       "Template title must be lowercase and can contain underscores only.",
      //   });
      // }

      console.log("this.contacts", this.contacts);
      self.loaderWait = true;
      try {
        if (this.companyDetails.whatsappCredits > this.contacts.length) {
          const resp = await self.$http.post(
            `${this.ENDPOINT}/whatsapps/sendBulkTemplates`,
            {
              campaign: { name: this.defaultItem.name },
              contactArray: this.contacts,
              template: this.selectedTemplates,
              // companyId: this.$store.state.user.companyId,
            }
          );
          if (resp.body == "Name already exists") {
            this.broadcastDialog = false;
            this.$swal({
              type: "error",
              text: "Campaign name already exists",
            });
            this.defaultItem.name = "";
            return;
          }

          this.broadcastDialog = false;
          // this.$swal({
          //   type: "success",
          //   text: `${campaign.campaign.campaignName} is created successfully`,
          // });
          this.defaultItem.name = "";
          this.$store.dispatch("getCompanyDetails", {
            companyId: this.user.companyId,
          });
          this.$router.go(0);
          return;
        } else {
          this.broadcastDialog = false;
          this.defaultItem.name = "";
          this.$swal({
            type: "error",
            text: "Not enough credits! Please buy more credits to continue",
          });
        }
      } catch (e) {
        console.log(e);
        this.broadcastDialog = false;
        this.defaultItem.name = "";
        this.$swal({
          type: "error",
          text: "An error occured!",
        });
      } finally {
        self.loaderWait = false;
      }

      // self.$http
      //   .post(`${this.ENDPOINT}/whatsapps/sendBulkTemplates`, {
      //     campaign: { name: this.defaultItem.name },
      //     contactArray: this.contacts,
      //     template: this.selectedTemplates,
      //     // companyId: this.$store.state.user.companyId,
      //   })
      //   .then((response) => {
      //     console.log("response.body", response.body);

      //     this.$swal({
      //       type: "success",
      //       text: "Campaign created successfully",
      //     }).then((result) => {
      //       // if (result.value) {
      //       //   self.$router.go(-1);
      //       // }
      //     });
      //   })
      //   .catch((e) => {
      //     console.log("e", e);
      //   });
    },
    validateStep1() {
      if (
        this.selectedItemCategory !== null &&
        this.template.title.trim() !== ""
      ) {
        this.e1 = 2; // Proceed to step 2
      } else {
        // Show validation error message or handle validation failure
        return this.$swal({
          type: "error",
          text: "Please select category and name",
        });
      }
    },
    setReminder() {
      //this.$moment().format()

      if (this.dateReminder == "0") {
        this.defaultItem.remindOn = new Date().toISOString();
      } else if (this.dateReminder == "1") {
        this.defaultItem.remindOn = new Date(
          moment().add(1, "days")
        ).toISOString();
      } else {
        this.defaultItem.remindOn = new Date().toISOString();
      }
    },
    async getTemplates() {
      //   this.preloader = true;
      // this.templates = [];
      // this.$http
      //   .get(
      //     this.$store.state.ENDPOINT +
      //       "/templates?filter[where][companyId]=" +
      //       this.$store.getters.user.companyId +
      //       "&filter[where][templateType]=Whatsapp"
      //   )
      //   .then(
      //     (response) => {
      //       response = response.body;
      //       this.allTemplates = response;

      //       console.log(" this.allTemplates", this.allTemplates);
      //       this.templates = this.allTemplates;

      //     },
      //     (error) => {

      //       this.$swal({
      //         type: "error",
      //         text: "Something went wrong while loading the templates.",
      //       });
      //     }
      //   );

      var self = this;
      this.allTemplates = [];
      await this.$http.get(self.ENDPOINT + "/whatsapps/getTemplates").then(
        (response) => {
          // self.preloader = false;
          response = response.data;
          if (response && response.data && Array.isArray(response.data)) {
            this.allTemplates = response.data.filter(
              (item) => item.status.toLowerCase() === "approved"
            );

            // this.allTemplates = response.data;

            // this.templates = _.filter(response.data, { status: "APPROVED" });
          } else {
            // f7.dialog.alert(
            //   `Invalid response format while loading the templates.`
            // );
            this.$swal({
              type: "error",
              text: "Invalid response format while loading the templates.",
            });
          }
          // this.preloader = false;
        },
        (error) => {
          // this.preloader = false;
          // f7.dialog.alert(`Error while fetching templates`);
          console.log("error fetching templates: ", error);
          this.$swal({
            type: "error",
            text: "Error while fetching templates.",
          });
        }
      );
    },
    async getContactsByList() {
      this.contactLength = "";
      try {
        this.warning = true;
        this.loader = true;
        // var response = await this.$http.get(
        //   `${this.ENDPOINT}/lists/${this.selectedlist}/contacts?filter[fields]=id`
        // );

        var response = await this.$http.post(
          `${this.$store.state.ENDPOINT}/contacts/byFilterV4`,
          {
            queryCase: "count",
            predicates: [],
            matchType: "",
            lists: [this.selectedlist],
            dispos: [],
          }
        );

        var contactResponse = await this.$http.post(
          `${this.$store.state.ENDPOINT}/contacts/byFilterV4`,
          {
            queryCase: "data",
            predicates: [],
            matchType: "",
            lists: [this.selectedlist],
            dispos: [],
          }
        );

        console.log("response count", response.body.count);

        this.contactLength = response.body.count;

        const idsArray = contactResponse.body.map((item) => item.id);
        this.contacts = idsArray;

        if (this.contactLength > 2000) {
          this.warning = true;
        } else {
          this.warning = false;
        }
        this.loader = false;
      } catch (e) {
        console.log(e);
      }
    },
    async fetchListByTeam() {
      const self = this;
      try {
        var teamsId = self.getTeamIds();

        var response = await self.$http.post(`${self.ENDPOINT}/lists/fetch`, {
          teams: teamsId,
        });
        self.lists = response.body;
      } catch (e) {
        console.log(e);
      }
    },
    getTeamIds() {
      var teams = [];
      if (this.selectedTeam == "allTeams") {
        teams = _.map(this.teams, "id");
      } else {
        teams.push(this.selectedTeam);
      }
      teams = _.without(teams, "allTeams");
      return teams;
    },
    close() {
      this.broadcastDialog = false;
    },
    async openBroadCastDialog() {
      this.broadcastDialog = true;
      this.selectedlist = this.lists[0].id;
      await this.getContactsByList();
      this.selectedTemplates = this.allTemplates[0];
    },
    deleteBroadcast(broadcast) {
      var self = this;

      this.$swal({
        text: `Are you sure you want to delete ${broadcast.name}`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "info",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          //this.loader = true;

          this.$http
            .delete(`${this.ENDPOINT}/broadcasts/${broadcast.id}`, {
              id: broadcast.id,
            })
            .then((response) => {
              //   this.refresh();
              //   this.loader = false;

              this.$swal({
                type: "success",
                text: `${broadcast.name} is deleted successfully`,
              });
            })
            .catch((err) => {
              this.loader = false;
              this.$swal({ type: "error", text: err.body.error.message });
            });
        }
      });
    },
    openBroadCast() { },
    addBroadcast() { },
  },
};
</script>

<style scoped>
/* .custom-position {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
} */

/* .container-position {
  position: relative;
} */

.wrap-text-title {
  white-space: normal;
}

.v-divider {
  margin: 5px 0px 15px 0px;
}

.v-btn:not(.v-btn--round).v-size--small {
  left: -25px !important;
  text-transform: none;
}

.v-card {
  box-shadow: 0px 8px 60px -10px rgba(13, 28, 39, 0.3);
}

.dotted-gradient {
  background-image: linear-gradient(to right,
      #313131 40%,
      rgba(255, 255, 255, 0) 40%);
  background-position: bottom;
  background-size: 6px 2px;
  background-repeat: repeat-x;
  text-transform: inherit;
  padding-bottom: 2px;
}

.v-card {
  transition: opacity 0.4s ease-in-out;
}

/*
.v-card:not(.on-hover) {
  opacity: 0.6;
 } */

.show-btns {
  color: rgba(0, 0, 0, 1) !important;
}

.text-caption-small {
  font-size: 11px;
  line-height: 1.4;
}

.add_button {
  padding: 10px !important;
  cursor: pointer;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 7px;
}

.add_button:hover {
  padding: 10px !important;
  cursor: pointer;
  transition-delay: 0.1s;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 14px;
  box-shadow: 1px 3px 9px #c3c3c3 !important;
}
</style>
